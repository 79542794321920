import { PositionTypes } from 'features/common/models';

import { actions } from './symbiotes';

export const Tabs = {
  wallet: { name: 'My funds', index: 0 },
  conversion: { name: 'Currency conversion', index: 1 },
};

export const PaymentStatuses = {
  ok: 'Success',
  failed: 'Failed',
  pending: 'Pending',
};

export const paymentSystemsModel = {
  EUR: 'SEPA',
  CHF: 'SWIFT',
  GBP: 'SWIFT',
};

export const minimumDepositAmount = {
  EUR: '20',
  CHF: '20',
  GBP: '40',
};

export const setPositionsActionsModel = {
  [PositionTypes.CURRENCY]: actions.setCurrencyPositions,
  [PositionTypes.CRYPTO]: actions.setCryptoPositions,
  [PositionTypes.ALT_COIN]: actions.setAltcoinPositions,
  [PositionTypes.VNX]: actions.setVnxPositions,
  [PositionTypes.DGR]: actions.setDGRPositions,
  [PositionTypes.FRT]: actions.setFRTPositions,
};
