export const TermTypes = {
  GENERAL_TERMS: 'GENERAL_TERMS',
  PRIVACY_TERMS: 'PRIVACY_TERMS',
  PERSONAL_DATA_TERMS: 'PERSONAL_DATA_TERMS',
  NON_RESIDENT_TERMS: 'NON_RESIDENT_TERMS',
  NON_RESIDENT_TERMS_LEGAL: 'NON_RESIDENT_TERMS_LEGAL',
  OLDER_18_TERMS: 'OLDER_18_TERMS',
  OWN_NAME_AND_INTEREST_TERMS: 'OWN_NAME_AND_INTEREST_TERMS',
  AUTHORIZED_REPRESENTATIVE_CONFIRMATION: 'AUTHORIZED_REPRESENTATIVE_CONFIRMATION',
  VNX_GOLD_BASED_FRT_TERMS: 'VNX_GOLD_BASED_FRT_TERMS',
  AGREE_EMAIL_PROMOUTIONS: 'AGREE_EMAIL_PROMOUTIONS',
};

export const termsDocType = {
  GENERAL: 'general',
  FRT: 'frt',
};

export const termsPath = {
  [termsDocType.GENERAL]: process.env.REACT_APP_TERMS_URL,
  [termsDocType.FRT]: process.env.REACT_APP_TERMS_FRT_URL,
};
