import { object, string } from 'yup';
import { withStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import React, { useState, Fragment, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, InputBase, InputAdornment, InputLabel, Link, Typography, FormControl, FormControlLabel, Grid, FormHelperText } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { ErrorDialog } from 'ui';

import { flatValidationErrors } from '../../../lib/validators';

import { PassRequire } from './pass-requirements';
import { Checkbox } from './checkbox';

const styles = theme => ({
  paper: {
    padding: '40px 30px 30px 30px',
    backgroundColor: '#FFFFFF',
  },
  form: {
    width: '100%',
  },
  mailingList: {
    color: '#fff',
    maxWidth: 'fit-content',
  },
  formButton: {
    marginBottom: theme.spacing(8),
  },
  inputRoot: {
    'input[type="password"]': {
      paddingRight: '40px',
    },
    'label + &': {
      marginTop: theme.spacing(6),
    },
    '& input': {
      '&:-webkit-autofill': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
      '&:-webkit-autofill:focus': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
      '&:-webkit-autofill:hover': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
    },
  },
  inputField: {
    fontSize: 14,
    width: '100%',
    background: '#393C43',
    color: '#fff',
    transition: theme.transitions.create([ 'border-color', 'box-shadow' ]),
    '&::placeholder': {
      textOverflow: 'ellipsis',
      color: '#9FA4AE',
    },
    '&:focus': {
      borderColor: '#9FA4AE',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  inputLabel: {
    color: '#fff',
    marginBottom: theme.spacing(2),
    '&.Mui-focused': {
      color: '#FFF',
    },
  },
  titleSignIn: {
    color: '#fff',
    paddingBottom: theme.spacing(8),
    '@media screen and (max-device-width: 500px)': {
      '& h4': {
        textAlign: 'center',
        fontSize: '22px',
      },
    },
  },
  row: {
    maxWidth: '499px',
    paddingBottom: theme.spacing(7),
  },
  grid: {
    color: '#fff',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(2),
    gap: '5px',
  },
  passwordIcon: {
    color: '#BCBCBC',
    position: 'absolute',
    right: '0px',
  },
  subTitle: {
    color: '#9FA4AE',
  },
  pswd_info: {
    color: 'white',
    marginTop: '10px',
    fontSize: '.875em',
  },
  invalid: {
    color: 'red',
  },
  valid: {
    color: '#44d437',
  },
  none: {
    display: 'none',
  },
});

let schema = object().shape({
  login: string().email().required(),
});

const SignUpFormView = ({ classes, handleProceed, submitForm, mailSubscribe, setMailSubscribe, form }) => {
  const [ showPassword, setShowPassword ] = useState(false);

  const [ login, setLogin ] = useState('');
  const [ password, setPassword ] = useState('');

  const [ error, setError ] = useState('');
  const [ errorShow, setErrorShow ] = useState(false);
  const [ validationErrors, setValidationErrors ] = useState({});

  const [ isEqual, setIsEqual ] = useState(false);
  const [ isMinLength, setIsMinLength ] = useState(false);
  const [ isHaveLower, setIsHaveLower ] = useState(false);
  const [ isHaveUpper, setIsHaveUpper ] = useState(false);
  const [ isHaveNumber, setIsHaveNumber ] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (password.toLocaleLowerCase() === login.toLocaleLowerCase()) {
      setIsEqual(true);
    } else {
      setIsEqual(false);
    }

    if (password.length >= 6) {
      setIsMinLength(true);
    } else {
      setIsMinLength(false);
    }

    if (password.match(/[A-Z]/)) {
      setIsHaveUpper(true);
    } else {
      setIsHaveUpper(false);
    }

    if (password.match(/[a-z]/)) {
      setIsHaveLower(true);
    } else {
      setIsHaveLower(false);
    }

    if (password.match(/\d/)) {
      setIsHaveNumber(true);
    } else {
      setIsHaveNumber(false);
    }
  }, [ password, login ]);

  const validPass = useMemo(
    () => !isEqual && isMinLength && isHaveLower && isHaveUpper && isHaveNumber,
    [ isEqual, isMinLength, isHaveLower, isHaveUpper, isHaveNumber ]
  );

  const proceed = async (event) => {
    event.preventDefault();
    try {
      const formValidated = await schema.validate({ login, password }, { abortEarly: false });
      setValidationErrors({});
      const { ok, data, error } = { ok: true, error: {}, data: formValidated };

      if (ok) {
        handleProceed(true);
        submitForm(data);
      } else {
        setError(error.data);
        setErrorShow(true);
      }
    } catch (e) {
      if (e.name === 'ValidationError') {
        setValidationErrors(flatValidationErrors(e.inner));
      }
      throw e;
    }
  };

  return (
    <Fragment>
      <Grid container alignItems="center" className={classes.titleSignIn} direction="column" justifyContent="center">
        <Typography variant="h4">Create your investor</Typography>
        <Typography variant="h4">account now</Typography>
        <Typography variant="body2">Easy sign up in just 30 seconds</Typography>
      </Grid>
      <form className={classes.form} id="Sign_up" onSubmit={proceed}>
        <Grid container className={classes.row}>
          <FormControl fullWidth error={validationErrors['login']} margin="normal">
            <InputLabel className={classes.inputLabel} htmlFor="email">
              <Typography variant="body2">Email</Typography>
            </InputLabel>
            <InputBase
              classes={{
                root: classes.inputRoot,
                input: classes.inputField,
              }}
              id="login"
              name="login"
              type="email"
              value={login}
              onChange={e => setLogin(e.target.value)}
            />
            <FormHelperText error={validationErrors['login']}>{validationErrors['login']}</FormHelperText>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel className={classes.inputLabel} htmlFor="password">
              <Typography variant="body2">Password</Typography>
            </InputLabel>
            <InputBase
              classes={{
                root: classes.inputRoot,
                input: classes.inputField,
              }}
              endAdornment={
                <InputAdornment className={classes.passwordIcon} position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    size="large"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              id="password"
              inputProps={{
                maxLength: '30',
                minLength: '6',
                autoComplete: 'true',
              }}
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <PassRequire
              classes={classes}
              display={password && !validPass}
              equal={isEqual}
              length={isMinLength}
              lower={isHaveLower}
              number={isHaveNumber}
              upper={isHaveUpper}
            />
          </FormControl>

          <FormControlLabel
            className={classes.mailingList}
            control={
              <Checkbox
                checked={mailSubscribe}
                value={String(mailSubscribe)}
                onChange={(e) => {
                  setMailSubscribe(e.target.checked);
                }}
              />
            }
            label={
              <Typography variant="body2">
                I agree to receive offers and promotions from VNX Commodities A.G. by email. This setting can be changed anytime.
              </Typography>
            }
            margin="normal"
          />
        </Grid>
        <Grid container alignItems="flex-start" className={classes.formButton} justifyContent="center">
          <Button fullWidth className="normal" disabled={!validPass || !login} size="large" type="submit">
            Proceed
          </Button>
        </Grid>
        <Grid container className={classes.grid}>
          <Grid item>
            <Typography className={classes.subTitle} variant="inherit">
              Have an account?
            </Typography>
          </Grid>
          <Grid item>
            <Link onClick={() => navigate('/join')}>Login</Link>
          </Grid>
        </Grid>
      </form>
      <ErrorDialog handleClose={setErrorShow} message={error} show={errorShow} />
    </Fragment>
  );
};

SignUpFormView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const SignUpForm = withStyles(SignUpFormView, styles);
